import "./App.css";
import Header from './components/header/Header';
import Hero from './components/hero/Hero';
import Slide from './components/Slider/Slider';
import Virtual from './components/Virtual/Virtual';
import Profucts from './components/Products/Products';
import Testimonia from "./components/Testimonials/Testionials";
import Footer from "./components/Footer/Footer";
function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Slide />
      <Virtual />
      <Profucts />
      <Testimonia />
      <Footer />
    </div>
  );
}

export default App;
