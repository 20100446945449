import React from "react";
import css from "./Testimonials.module.css"
import Hero from "../../assets/testimonialHero.png"
import { TestimonialsData } from '../../data/testimonials'
import { Swiper, SwiperSlide } from "swiper/react";
// import { SwiperSlide } from "swiper/react"; wrapper
const Testimonia = () => {
    return (
        <div className={css.testimonials}>
            <div className={css.wrapper}>
                <div className={css.container}>
                    <span>Top Rated</span>
                    <span>
                        Seedily say has suitable disposal and boy. Exercise joy man children rejoiced.
                    </span>
                </div>
                <img src={Hero} alt="" />
                <div className={css.container}>
                    <span>100K</span>
                    <span>Happy Customers with us</span>
                </div>
            </div>
            <div className={css.review}>Review</div>
            <div className={css.carousel}>
                <Swiper slidesPerView={3}
                    slidesPerGroup={1}
                    spaceBetween={20}
                    breakpoints={{
                        856: {
                            slidesPerView: 3
                        },
                        640: {
                            slidesPerView: 2
                        },
                        0: {
                            slidesPerView: 0
                        }
                    }}
                    className={css.tCarousel}>
                    {
                        TestimonialsData.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className={css.testimonial}>
                                        <img src={item.image} alt="" />
                                        <span>{item.comment}</span>
                                        <hr />
                                        <span>{item.name}</span>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>

    );
}
export default Testimonia;